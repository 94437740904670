class ProductCard extends HTMLElement {
  constructor() {
    super()

    this.selectors = {
      triggerInfo: '.js-trigger-info',
      parent: '.js-product-card-parent',
      close: '.js-trigger-close'
    }

    this.classes = {
      active: 'active'
    }

    this.triggerInfoEls = this.querySelectorAll(this.selectors.triggerInfo)
    if (this.triggerInfoEls.length > 0) {
      this.triggerInfo()
    }
  }

  triggerInfo() {
    this.triggerInfoEls.forEach(triggerInfo => {
      this.commonTrigger(triggerInfo)
    })
  }

  commonTrigger(el) {
    el.addEventListener('click', () => {
      const { id } = el.dataset
      const dataInfoEl = this.querySelector(`#${id}`)

      if (dataInfoEl) {
        if (window.innerWidth < 768) {
          this.closeAll(el)
          this.triggerMobileBehavior(dataInfoEl, el, id)
        } else {
          dataInfoEl.classList.toggle(this.classes.active)
          this.triggerCloseDesktop(dataInfoEl)
        }
      }
    })
  }

  triggerMobileBehavior(dataInfoEl, triggerEl, id) {
    const cloneEl = dataInfoEl.cloneNode(true)
    cloneEl.id = `${id}-clone`
    cloneEl.setAttribute('data-product-info-clone', 'true')

    const parentEl = triggerEl.closest(this.selectors.parent)
    parentEl.appendChild(cloneEl)

    cloneEl.classList.add(this.classes.active)
    document.body.classList.add('overflow-hidden')
    this.initTriggerClose(cloneEl)

    // Add overlay
    let overlayEl = document.querySelector('body > .quickadd-overlay')
    if (!overlayEl) {
      overlayEl = document.createElement('div')
      overlayEl.classList.add('quickadd-overlay')
      document.body.appendChild(overlayEl)
    }
    overlayEl.classList.add('is-active')
  }

  initTriggerClose(el) {
    const triggerCloseEl = el.querySelector(this.selectors.close)

    if (triggerCloseEl) {
      triggerCloseEl.addEventListener('click', () => {
        document.body.classList.remove('overflow-hidden')
        el.remove()

        const overlayEl = document.querySelector('body > .quickadd-overlay')
        if (overlayEl) {
          overlayEl.classList.remove('is-active')
        }
      })
    }
  }

  triggerCloseDesktop (el) {
    const triggerCloseEl = el.querySelector(this.selectors.close)
    triggerCloseEl && triggerCloseEl.addEventListener('click', () => {
      el.classList.toggle(this.classes.active)
    })
  }

  closeAll(el) {
    let parentEl = el ? el.closest('.shopify-section') : document

    const productInfoClones = parentEl.querySelectorAll('[data-product-info-clone]')
    productInfoClones.forEach(cloneEl => cloneEl.remove())

    const overlayEl = document.querySelector('body > .quickadd-overlay')
    if (overlayEl) {
      overlayEl.classList.remove('is-active')
    }

    const productInfos = parentEl.querySelectorAll('.product-card__info')
    productInfos.forEach(infoEl => infoEl.classList.remove(this.classes.active))
  }
}

window.customElements.define('product-card', ProductCard)
